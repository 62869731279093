<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Egg Sale</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> List </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="6">
						<b-row>
							<b-col md="5">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.from_date"
								/>
							</b-col>
							<b-col md="5">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="getEggSale()" variant="primary "> Filter </b-button>
							</b-col>
						</b-row>
					</b-col>
					<!-- Search -->
					<b-col cols="12" md="6" class="mt-2 d-flex justify-content-end align-items-center">
						<div>
							<b-form-input
								type="text"
								class="form-control"
								placeholder="Search"
								@input="getEggSale()"
								v-model.trim="search_filter.input_field"
								style="border-radius: 4px"
							/>
						</div>
						<b-button
							@click="clearFilter"
							variant="secondary
 			 ml-1"
						>
							Clear
						</b-button>
						<b-button v-b-modal.hatch-mortality variant="primary mr-1 ml-1"> Add </b-button>
						<b-button @click="excelDownload()" variant="warning">
							<feather-icon icon="DownloadIcon" size="16" />
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="egg_saleInformationAll && egg_saleInformationAll.total > 0">
				<b-table
					:items="egg_saleInformationAll.data"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ egg_saleInformationAll.from + data.index }}
						</div>
					</template>
				</b-table>

				<div class="mx-2 mb-2">
					<b-pagination
						:value="egg_saleInformationAll.current_page"
						:total-rows="egg_saleInformationAll.total"
						:per-page="egg_saleInformationAll.per_page"
						align="right"
						@change="getEggSale"
					>
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
				<h4 class="mt-2 Secondary">No data Found</h4>
			</div>
			<!-- hatch mortality modal -->
			<b-modal id="hatch-mortality" title="Egg Sale" ok-only @ok="addEggSale" ok-title="Submit" ref="hatch-mortality">
				<validation-observer ref="simpleRules">
					<form ref="form">
						<b-form-group>
							<template v-slot:label> Sale Date <span class="text-danger">*</span> </template>
							<div class="form-label-group">
								<validation-provider #default="{ errors }" name="Sale Date" rules="required">
									<b-form-datepicker v-model="FormData.sale_date" placeholder="Choose a date" local="en" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
						<b-form-group>
							<template v-slot:label> Flock ID<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Flock ID" rules="required">
								<v-select
									v-model="FormData.flock_id"
									:options="flockDataList"
									label="flock_id"
									:reduce="(e) => e.flock_id"
									:clearable="false"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<b-form-group label="No. of Mortality">
							<template v-slot:label> No. of Eggs<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Enter No. of Eggs" rules="required">
								<b-form-input v-model="FormData.quantity" name="quantity" type="text" placeholder="Enter No. of Eggs" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group label="Weight (g)">
							<template v-slot:label> Weight (g)<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Weight (g)" rules="required">
								<b-form-input v-model="FormData.weight" name="weight" type="text" placeholder="Weight (g)" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group label="Unit Cost Rs.">
							<template v-slot:label> Unit Cost Rs.<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Unit Cost Rs." rules="required">
								<b-form-input v-model="FormData.unit_cost" name="unit_cost" type="text" placeholder="Unit Cost Rs." />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group label="Total Sale Cost (Rs.)">
							<template v-slot:label> Total Sale Cost (Rs.)<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Total Sale Cost (Rs.)" rules="required">
								<b-form-input
									v-model="FormData.total_amount"
									name="total_amount"
									type="text"
									placeholder="Total Sale Cost (Rs.)"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label> Sold To<span class="text-danger">*</span> </template>
							<div class="form-label-group">
								<validation-provider #default="{ errors }" name=" Source" rules="required">
									<v-select
										v-model="FormData.source_id"
										:options="sourceList"
										label="name"
										clearable:false
										:reduce="(e) => e.id"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
					</form>
				</validation-observer>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useMortalitySaleList from "./useMortalitySaleList";
import saleStoreModules from "../saleStoreModules";
import axiosIns from "@/libs/axios";
import moment from "moment";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			required,
			selected: null,
			egg_saleInformationAll: null,
			selectedFlockId: null,
			flockList: {},
			rows: null,
			value: "",
			weight: null,
			unit_cost: null,
			FormData: {
				flock_id: null,
				created_at: null,
				quantity: null,
				weight: null,
				unit_cost: null,
				total_amount: null,
				source_id: null,
			},
			option: [],
			flockId: [],
			flockDataList: [],
			flockUsableEggList: [],
			egg_saleInformationAll: [],
			fields: [
				{ key: "index", label: "s.no" },
				{ key: "flock_id", label: "flock-id" },
				{
					key: "created_at",
					label: "sale_date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
				{ key: `quantity`, label: "No. of Eggs" },
				{ key: `weight`, label: "Weight" },
				{ key: `unit_cost`, label: "Unit Cost" },
				{ key: `total_amount`, label: "Total Cost" },
				{ key: `source.name`, label: "Sold To" },

				// "Actions",
			],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
		};
	},
	created() {
		this.farmId = this.$route.params.farmId;
		if (this.farmId) {
			this.getEggSale();
			this.getFlockList();
		}
		this.getSource();
	},
	methods: {
		getEggSale(pageNo = 1) {
			const farmId = this.$route.params.farmId;
			const saleType = "egg";
			let url = `web/farm/${farmId}/sale_type/${saleType}/getSaleInformation`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.egg_saleInformationAll = response.data.egg_saleInformationAll;
					// this.hatchList = response.data.phm_saleInformationAll;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSource() {
			axiosIns
				.get(`web/get-all-sources`)
				.then((response) => {
					this.sourceList = response.data.source_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFlockList() {
			const farmId = this.$route.params.farmId;
			axiosIns
				.get(`farm/${farmId}/flock-list`)
				.then((response) => {
					this.flockDataList = response.data.flock_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addEggSale(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					e.preventDefault();
					this.farmId = this.$route.params.farmId;
					let flockId = this.FormData.flock_id;
					const saleType = "egg";
					var data = new FormData();
					data.append("unit_cost", this.FormData.unit_cost);
					data.append("weight", this.FormData.weight);
					data.append("quantity", this.FormData.quantity);
					data.append("source_id", this.FormData.source_id);
					data.append("sale_type", saleType);
					axiosIns
						.post(`web/farm/${this.farmId}/flock/${flockId}/sales`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$refs["hatch-mortality"].hide();
							this.selectedHatchId = "";
							this.mortality = "";
							this.getEggSale();
						})

						.catch((error) => {
							error.response;
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");
							arr, "array";
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
							this.selectedHatchId = "";
							this.mortality = "";
							this.getEggSale();
						});
				}
			});
		},

		excelDownload() {
			const saleType = "egg";
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-post-egg-sale/${saleType}`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getEggSale();
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
