<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Feed
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-purchase" method="post" @submit="addFeed">
				<b-card>
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Purchase Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="purchase_date" rules="required">
									<b-form-datepicker
										name="purchase_date"
										v-model="FormData.purchase_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Production Type<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Production Type" rules="required">
										<v-select
											v-model="FormData.production_type_id"
											:options="productionTypes"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
											@input="getFeedTypes"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- <b-col md="6">
							<b-form-group>
								<template v-slot:label> Breed Type<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Breed Type" rules="required">
										<v-select
											v-model="FormData.breed_id"
											:options="breeds"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Breed Stages<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Breed Stage" rules="required">
										<v-select
											v-model="FormData.breed_stage_id"
											:options="stages"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
											@input="
												getFeedTypes(
													FormData.production_type_id,
													FormData.breed_id,
													FormData.breed_stage_id
												)
											"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col> -->
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Feed Type<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Feed Type" rules="required">
										<v-select
											v-model="FormData.feed_type_id"
											:options="feed_type"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Source<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Source" rules="required">
										<v-select
											v-model="FormData.source_id"
											:options="sourceList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Feed Name<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Feed Name" rules="required">
										<v-select
											v-model="FormData.feed_id"
											:options="feedList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Quantity <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Quantity"
										:rules="{
											required: true,
											regex: RegExp(/^\d+(\.\d{1,4})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.quantity"
											placeholder="Quantity"
											type="text"
											name="total_stock"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Rate (Rs.) Per Kg<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name=" Rate (Rs.) Per Kg"
										:rules="{ regex: RegExp(/^\d+(\.\d{0,2})?$/) }"
									>
										<b-form-input
											v-model="FormData.rate_per_kg"
											placeholder="Rate (Rs.) Per Kg"
											type="text"
											name="rate_per_kg"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Batch No. <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Batch No. " rules="required">
										<b-form-input v-model="FormData.batch_no" placeholder="Batch No. " type="text" name="batch_no" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- submit and reset -->
						<b-col cols="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import * as Vue from "vue";
import axios from "axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			regex,
			selected: null,
			FormData: {
				purchase_date: null,
				production_type: null,
				source_id: null,
				feed_id: null,
				quantity: null,
				rate_per_kg: null,
				batch_no: null,
				breed_id: null,
				breed_stage_id: null,
			},
			feedList: [],
			productionTypes: [],
			sourceList: [],
			breeds: [],
			stages: [],
			feed_type: [],
		};
	},
	created: function () {
		// this.getFeedTypes();
		this.getSourceTypes();
		this.getProductionTypes();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getSourceTypes() {
			axiosIns
				.get(`web/get-all-sources`)
				.then((response) => {
					this.sourceList = response.data.source_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
					this.feed_type = response.data.feed_type;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFeedTypes(productionType) {
			axiosIns
				.get(`web/get-feeds/${productionType}`)
				.then((response) => {
					this.feedList = response.data.feed_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addFeed(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("purchase_date", this.FormData.purchase_date);
					data.append("production_type_id", this.FormData.production_type_id);
					// data.append("breed_stage_id", this.FormData.breed_stage_id);
					// data.append("breed_id", this.FormData.breed_id);
					data.append("feed_type_id", this.FormData.feed_type_id);
					data.append("source_id", this.FormData.source_id);
					data.append("feed_id", this.FormData.feed_id);
					data.append("quantity", this.FormData.quantity);
					data.append("rate_per_kg", this.FormData.rate_per_kg);
					data.append("batch_no", this.FormData.batch_no);
					const farm_id = this.$route.params.farmId;
					axiosIns
						.post(`web/farm/${farm_id}/purchase-feed`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								name: `apps-purchase`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
		getBreedType(productionType) {
			axiosIns
				.get(`web/breeds/${productionType}`)
				.then((response) => {
					this.breeds = response.data.breeds;
					this.stages = response.data.stages;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
