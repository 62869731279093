var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Medicine ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"action":"apps-purchase","method":"post"},on:{"submit":_vm.addMedicine}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Purchase Date"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"purchase_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"name":"purchase_date","menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.FormData.purchase_date),callback:function ($$v) {_vm.$set(_vm.FormData, "purchase_date", $$v)},expression:"FormData.purchase_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Production Type"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Production Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.productionTypes,"label":"name","clearable:false":"","reduce":function (e) { return e.id; }},model:{value:(_vm.FormData.production_type_id),callback:function ($$v) {_vm.$set(_vm.FormData, "production_type_id", $$v)},expression:"FormData.production_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Source"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Source","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.sourceList,"label":"name","clearable:false":"","reduce":function (e) { return e.id; }},model:{value:(_vm.FormData.source_id),callback:function ($$v) {_vm.$set(_vm.FormData, "source_id", $$v)},expression:"FormData.source_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Medicine Type"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Medicine Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.medicineList,"label":"name","clearable:false":""},on:{"input":_vm.setRouteType},model:{value:(_vm.FormData.medicine_id),callback:function ($$v) {_vm.$set(_vm.FormData, "medicine_id", $$v)},expression:"FormData.medicine_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Unit")]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"label":"unit","type":"text","value":_vm.unit,"disabled":""},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)])],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quantity"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Quantity","rules":{
											required: true,
											regex: RegExp(/^\d+(\.\d{1,4})?$/),
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Quantity","type":"text","name":"quantity","maxlength":"10"},model:{value:(_vm.FormData.quantity),callback:function ($$v) {_vm.$set(_vm.FormData, "quantity", $$v)},expression:"FormData.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{staticStyle:{"align-self":"center"},attrs:{"md":"1"}},[(_vm.unit === "ml")?_c('div',{staticClass:"form-label-group"},[_vm._v("Liter")]):(_vm.unit === "g" || _vm.unit === "mg" || _vm.unit === "kg")?_c('div',{staticClass:"form-label-group"},[_vm._v("Kg")]):_vm._e()]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Expiry date"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":" Expiry date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"name":"expiry_date","menu-class":"w-100","calendar-width":"100%","id":"dropdown-offset"},model:{value:(_vm.FormData.expiry_date),callback:function ($$v) {_vm.$set(_vm.FormData, "expiry_date", $$v)},expression:"FormData.expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Total Cost (Rs.)"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Total Cost (Rs.)","rules":{
											required: true,
											regex: RegExp(/^\d+(\.\d{0,2})?$/),
										}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Total Cost (Rs.)","type":"text","name":"total_cost","maxlength":"10"},model:{value:(_vm.FormData.total_cost),callback:function ($$v) {_vm.$set(_vm.FormData, "total_cost", $$v)},expression:"FormData.total_cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Batch No. "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Batch No. ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Batch No. ","type":"text","name":"batch_no"},model:{value:(_vm.FormData.batch_no),callback:function ($$v) {_vm.$set(_vm.FormData, "batch_no", $$v)},expression:"FormData.batch_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }