<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-12 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Master Data</h2>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-card no-body>
			<b-tabs class="m-2">
				<b-tab active>
					<template #title>
						<b-img :src="require('@/assets/images/svg/sources.svg')" style="width: 25px; margin-right: 5px" />
						<span>Source</span>
					</template>
					<SourceList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<b-img :src="require('@/assets/images/svg/feed-supplement.svg')" style="width: 22px; margin-right: 7px" />
						<span>Feed/Supplement</span>
					</template>
					<FeedListVue />
				</b-tab>
				<b-tab lazy @shown="initializeDatePicker">
					<template #title>
						<b-img :src="require('@/assets/images/svg/disease.svg')" style="width: 22px; margin-right: 7px" />
						<span>Disease</span>
					</template>
					<DiseaseList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<b-img :src="require('@/assets/images/svg/vaccine.svg')" style="width: 25px; margin-right: 5px" />
						<span>Vaccine </span>
					</template>
					<VaccineList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<b-img :src="require('@/assets/images/svg/medicine.svg')" style="width: 22px; margin-right: 7px" />
						<span>Medicine/Disinfectant</span>
					</template>
					<medicine-list />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";
import SourceList from "./SourceList.vue";
import VaccineList from "./VaccineList.vue";
import MedicineList from "./MedicineList.vue";
import BreedList from "./BreedList.vue";
import FeedListVue from "./FeedList.vue";
import DiseaseList from "./DiseaseList.vue";
// import DiseaseList1 from "./DiseaseList.vue";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BFormGroup,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,
		vSelect,
		BFormDatepicker,
		BFormSelect,
		SourceList,
		VaccineList,
		MedicineList,
		BreedList,
		FeedListVue,
		DiseaseList,
		BImg,
		// DiseaseList1
	},
	data() {
		return {
			rows: null,
		};
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
