<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Vaccine
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-purchase" method="post" @submit="addVaccine">
				<b-card>
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Purchase Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Purchase Date" rules="required">
									<b-form-datepicker
										name="purchase_date"
										v-model="FormData.purchase_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Production Type<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Production Type" rules="required">
										<v-select
											v-model="FormData.production_type_id"
											:options="productionTypes"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Source<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Source" rules="required">
										<v-select
											v-model="selectedSource"
											:options="sourceList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Vaccine Type<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Type" rules="required">
										<v-select
											v-model="selectedVaccine"
											:options="vaccineList"
											label="name"
											clearable:false
											@input="setRouteType"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label>Unit</template>
								<div class="form-label-group">
									<b-form-input v-model="unit" label="unit" type="text" :value="unit" disabled />
								</div>
							</b-form-group>
						</b-col>
						<!--    -->
						<!-- <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Pack Size<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Pack Size"
                    rules="required|regex:^([0-9]+)$"
                  >
                    <b-form-input
                      v-model="FormData.pack_size"
                      placeholder="Pack Size"
                      type="text"
                      name="total_stock"
                      maxlength="10"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col> -->
						<b-col md="5">
							<b-form-group>
								<template v-slot:label> Quantity<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Quantity"
										:rules="{
											required: true,
											regex: RegExp(/^\d+(\.\d{1,4})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.quantity"
											placeholder="Quantity"
											type="text"
											name="total_stock"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="1" style="align-self: center">
							<div class="form-label-group" v-if="unit === `Dose`">Dose</div>
							<div class="form-label-group" v-else-if="unit === `ml` || unit === `drops` || unit === `spray`">
								Liter
							</div>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Expiry Date<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Expiry Date" rules="required">
									<b-form-datepicker
										name="expiry_date"
										v-model="FormData.expiry_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Total Cost (Rs.)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Total Cost (Rs.)"
										:rules="{ regex: RegExp(/^\d+(\.\d{0,2})?$/) }"
									>
										<b-form-input
											v-model="FormData.total_cost"
											placeholder="Total Cost (Rs.)"
											type="text"
											name="total_cost"
											maxlength="10"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Batch No. <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Batch No. " rules="required">
										<b-form-input v-model="FormData.batch_no" placeholder="Batch No. " type="text" name="batch_no" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";
import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			regex,
			required,
			selected: null,
			FormData: {
				purchase_date: null,
				production_type_id: null,
				expiry_date: null,
				pack_size: null,
				rate_per_pack: null,
				batch_no: null,
			},
			selectedVaccine: null,
			selectedSource: null,
			selectedProductionType: null,
			productionTypes: [],
			vaccineList: [],
			sourceList: [],
			unit: null,
			unit_id: null,
		};
	},
	created: function () {
		this.getProductionTypes();
		this.getSourceTypes();
		this.getVaccine();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getSourceTypes() {
			axiosIns
				.get(`web/source`) // Change the API, Convert into the Array Format
				.then((response) => {
					this.sourceList = response.data.source_list.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getVaccine() {
			axiosIns
				.get(`web/get-all-vaccine`) // Change the API, Convert into the Array Format
				.then((response) => {
					this.vaccineList = response.data.vaccine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		// getSelectedUnit(id) {
		//   this.selectedUnit = id;
		// },
		// getSelectedRoute(id) {
		//   this.selectedRoute = id;
		// },
		setRouteType(event) {
			this.unit = event.unit.name;
			this.unit_id = event.unit.id;
			this.vaccine_id = event.id;
		},
		addVaccine(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("purchase_date", this.FormData.purchase_date);
					data.append("production_type_id", this.FormData.production_type_id);
					data.append("source_id", this.selectedSource);
					data.append("vaccine_id", this.vaccine_id);
					data.append("expiry_date", this.FormData.expiry_date);
					// data.append("pack_size", this.FormData.pack_size);
					data.append("quantity", this.FormData.quantity);
					data.append("total_cost", this.FormData.total_cost);
					data.append("batch_no", this.FormData.batch_no);
					const farm_id = this.$route.params.farmId;
					axiosIns
						.post(`web/farm/${farm_id}/purchase-vaccine`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								name: `apps-purchase`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
