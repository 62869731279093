<template>
	<div>
		<div class="row justify-content-end mb-2">
			<b-col md="3">
				<b-form-datepicker
					id="from-date"
					:date-format-options="{
						year: 'numeric',
						month: 'short',
						day: '2-digit',
						weekday: 'short',
					}"
					placeholder="Choose a date"
					local="en"
					v-model="from_date"
				/>
			</b-col>
			<div md="1" class="d-flex align-items-center ml-1">to</div>
			<b-col md="3">
				<b-form-datepicker
					id="to-date"
					:date-format-options="{
						year: 'numeric',
						month: 'short',
						day: '2-digit',
						weekday: 'short',
					}"
					placeholder="Choose a date"
					local="en"
					v-model="to_date"
					@input="getData()"
				/>
			</b-col>
		</div>

		<div class="row match-height">
			<div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img
										:src="require('@/assets/images/dashboard/number_of_birds.svg')"
										style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>No of Birds</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25">
								{{ broilerInfo ? broilerInfo.totalBirds : 0 }}
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img
										:src="require('@/assets/images/dashboard/mortality.svg')"
										style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>Mortality Rate</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25">{{ this.broilerInfo ? this.broilerInfo.mortalityRate + "%" : 0 }}</h3>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img
										:src="require('@/assets/images/dashboard/ready_sell.svg')"
										style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>Ready To Sell(Birds)</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25">
								{{ broilerInfo ? broilerInfo.readyToSale : 0 }}
							</h3>
						</div>
					</div>
				</div>
			</div> -->
			<div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img
										:src="require('@/assets/images/dashboard/feed_stock.svg')"
										style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>Feed Stock</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25">
								{{ broilerInfo && broilerInfo.feedStock ? broilerInfo.feedStock : 0 }}
							</h3>
						</div>
					</div>
				</div>
			</div>

			<div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img
										:src="require('@/assets/images/dashboard/average_body.svg')"
										style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>Avg Body Weight</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25 font-weight-bolder">
								{{ this.broilerInfo ? this.broilerInfo.avgBodyWeight : 0 }}
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img
										:src="require('@/assets/images/dashboard/chick_placement.svg')"
										style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>Chick Placement</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25 font-weight-bolder">
								{{ this.broilerInfo ? this.broilerInfo.chickPlacement : 0 }}
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-2 col-lg-3">
				<div class="card d-flex" @click="broilerDetails">
					<div class="card-body d-flex pb-0">
						<div class="col-7 pr-0">
							<span class="b-avatar mb-1 badge-light-info rounded-circle" style="width: 75px; height: 75px"
								><span class="b-avatar-custom"
									><b-img :src="require('@/assets/images/dashboard/fcr.svg')" style="height: 75px; width: 75px" /></span
							></span>
							<div class="truncate mb-2">
								<span>FCR</span>
							</div>
						</div>
						<div class="col-5 card-value">
							<h3 class="mb-25 font-weight-bolder">
								{{ this.broilerInfo ? this.broilerInfo.FCR + "%" : 0 }}
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<b-row>
			<b-col md="6">
				<ChartjsLineChart />
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardTitle,
	BCardText,
	BCardBody,
	BRow,
	BCol,
	BMedia,
	BMediaAside,
	BAvatar,
	BMediaBody,
	BCarousel,
	BCarouselSlide,
	BButton,
	BImg,
	BFormDatepicker,
} from "bootstrap-vue";

import ChartjsLineChart from "../../charts-and-maps/charts/chartjs/ChartjsLineChart.vue";
import BroilerDashboard from "../dashboard/BroilerDashboard.vue";
import BreederDashboard from "../dashboard/BreederDashboard.vue";
import LayerDashboard from "../dashboard/LayerDashboard.vue";
import axiosIns from "@/libs/axios";
import VueApexCharts from "vue-apexcharts";
import vmson from "@/libs/empty";
import moment from "moment";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardText,
		BCardBody,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BButton,
		BroilerDashboard,
		BreederDashboard,
		LayerDashboard,
		VueApexCharts,
		BCarousel,
		BCarouselSlide,
		BImg,
		BFormDatepicker,
		ChartjsLineChart,
	},
	data: () => {
		return {
			broilerInfo: null,
			slide: null,
			sliding: null,
			fromDate: null,
			toDate: null,
			carouselItems: [],
			series: [44],
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			total_birds: null,
			feed_stock: null,
			ready_to_sale: null,
			chick_placement: null,
			avg_body_weight: null,
			fcr: null,
			mortality_rate: null,
			farmId: null,
		};
	},
	created() {
		this.fromDate = moment().clone().startOf("month").format("YYYY-MM-DD");
		this.toDate = moment().clone().endOf("month").format("YYYY-MM-DD");
		this.farmId = this.$route.params.farmId;
		this.getData();
	},
	methods: {
		broilerDetails(type) {
			let farmId = this.$route.params.farmId;
			if (farmId) {
				this.$router.push(`/apps/manage-farm/${farmId}/dashboard/broiler`);
			} else {
				this.$router.push({
					path: `/apps/dashboard/broiler`,
				});
			}
		},
		getData(id) {
			let url = "";
			let farmId = null;
			if (id) {
				farmId = id;
			} else if (this.farmId) {
				farmId = this.farmId;
			}

			if (farmId) {
				if (this.from_date != null && this.to_date != null) {
					url = `web/broiler-data/${farmId}/dashboard?from_date=${this.from_date}&to_date=${this.to_date}`;
				} else {
					url = `web/broiler-data/${farmId}/dashboard?from_date=${this.fromDate}&to_date=${this.toDate}`;
				}
			} else {
				if (this.from_date != null && this.to_date != null) {
					url = `web/broiler-data?from_date=${this.from_date}&to_date=${this.to_date}`;
				} else {
					url = `web/broiler-data?from_date=${this.fromDate}&to_date=${this.toDate}`;
				}
			}
			axiosIns
				.get(url)
				.then((response) => {
					this.broilerInfo = response.data.broiler_data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		onSlideStart(slide) {
			this.sliding = true;
		},
		onSlideEnd(slide) {
			this.sliding = false;
		},
	},
	mounted() {
		vmson.$on("farmIdEvent", (id) => {});
	},
};
</script>
<style lang="scss">
.mb-25 {
	font-size: 1rem !important;
}
.chart {
	// display: flex;
	justify-content: center;
}
.card-value {
	align-self: center;
	text-align: center;
}
.carousel-item {
	background: transparent !important;
}
.carousel-item img {
	height: 200px !important ;
}
.carousel-indicators li {
	display: none;
}
.item {
	background: transparent;
	height: 200px;
	width: 600px !important;
	object-fit: contain !important;
	margin-bottom: 1rem;
}
.item.active {
	background: transparent !important;
}
.carousel-control-prev,
.carousel-control-next {
	display: none;
}

.card:hover {
	cursor: pointer;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/hatchery.scss";
@import "@core/scss/vue/pages/_common.scss";
</style>
