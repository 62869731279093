<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Vaccination Entry
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-data-entry-list" method="post" @submit="addVaccine">
				<b-card>
					<!-- <div class="d-flex justify-content-end mb-0 mt-2 mr-2">
						<b-button variant="primary"> Total Alive Birds : {{ this.total_chick ? total_chick : 0 }} </b-button>
					</div> -->
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Date of Vaccination<span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Entry Date" :rules="{ required: true }">
									<b-form-datepicker
										v-model="FormData.entry_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Vaccine<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Select Vaccine" rules="required">
										<v-select
											v-model="FormData.vaccine_id"
											:options="vaccineList"
											label="name"
											clearable:false
											@input="setRouteType"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label>Route</template>
								<div class="form-label-group">
									<b-form-input v-model="routeType" label="routeType" type="text" :value="routeType" disabled />
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label>Unit</template>
								<div class="form-label-group">
									<b-form-input v-model="unit" label="unit" type="text" :value="unit" disabled />
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Batch No<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Select Batch No" rules="required">
										<v-select
											v-model="FormData.batch_no"
											:options="batchList"
											label="batch_no"
											clearable:false
											@input="getBatchDetails"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> No. of Birds<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="No. of Birds"
										:rules="{
											required: true,
											max_value: this.flockData.alive_birds,
										}"
									>
										<b-form-input
											v-model="FormData.birds_quantity"
											placeholder="No Of Birds"
											type="text"
											name="birds_quantity"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- <b-col md="6">
							<b-form-group>
								<template v-slot:label> Pack Size</template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Pack Size">
										<b-form-input
											v-model="batchDetails.pack_size"
											placeholder="Pack Size"
											type="text"
											name="pack_size"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col> -->
						<b-col md="5">
							<b-form-group>
								<template v-slot:label> Total Quantity</template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Total Quantity">
										<b-form-input
											v-model="batchDetails.quantity"
											placeholder="Total Quantity"
											type="text"
											name="total quantity"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="1" style="align-self: center">
							<div class="form-label-group" v-if="unit === `ml`">ml</div>
							<div class="form-label-group" v-else-if="unit === `Dose`">Dose</div>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Quantity administered<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Quantity administered"
										:rules="{
											required: true,
											max_value: this.batchDetails.quantity,
											regex: RegExp(/^\d+(\.\d{1,4})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.quantity_administered"
											placeholder="Quantity administered"
											type="text"
											name="quantity_administered"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>

						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selected: null,
			vaccine_id: null,
			FormData: {
				vaccine_id: null,
				batch_no: null,
				birds_quantity: null,
				pack_quantity: null,
				route_type_id: null,
				pack_size: null,
				// expiry_date: null,
				// price_per_vaccine: null,
			},
			selectedVaccine: null,
			routeList: [],
			vaccineList: [],
			batchList: [],
			routeType: null,
			flockData: [],
			route_type_id: null,
			batchDetails: [],
			batch_no: null,
			quantity_administered: null,
			unit: null,
		};
	},
	created: function () {
		this.getVaccines();
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		this.getFlockDetails();
	},
	methods: {
		goBack() {
			this.$router.push(`/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`);
		},

		setRouteType(event) {
			this.routeType = event.route.name;
			this.route_type_id = event.route.id;
			this.unit = event.unit.name;
			this.unit_id = event.unit.id;
			this.vaccine_id = event.id;
			this.getBatchListForSelectedVaccine(event.id);
		},
		getVaccines() {
			axiosIns
				.get(`web/get-all-vaccine`)
				.then((response) => {
					this.vaccineList = response.data.vaccine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBatchListForSelectedVaccine(vaccine_id) {
			axiosIns
				.get(`web/farm/${this.farmId}/vaccine/${vaccine_id}/batch-list`)
				.then((response) => {
					this.FormData.batch_no = "";
					this.batchList = response.data.batch_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBatchDetails(batchDetails) {
			this.batch_no = batchDetails.batch_no;
			let batch_id = batchDetails.id;
			axiosIns
				.get(`web/farm/${this.farmId}/vaccine/batch/${batch_id}/batch-info`)
				.then((response) => {
					this.batchDetails = response.data.batch_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFlockDetails() {
			axiosIns
				.get(`web/flock-details/${this.flockId}`)
				.then((response) => {
					this.flockData = response.data.flock_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addVaccine(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("batch_no", this.batch_no);
					data.append("vaccine_id", this.vaccine_id);
					data.append("quantity_administered", this.FormData.quantity_administered);
					data.append("route_type_id", this.route_type_id);
					data.append("birds_quantity", this.FormData.birds_quantity);
					data.append("unit_id", this.unit_id);
					data.append("entry_date", this.FormData.entry_date);
					const farm_id = this.$route.params.farmId;
					axiosIns
						.post(`web/farm/${this.farmId}/shed/${this.shedId}/flock/${this.flockId}/vaccination-entry`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								path: `/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
