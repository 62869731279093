<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />Feed/Supplement Entry
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-data-entry-list" method="post" @submit="addFeed">
				<b-card>
					<!-- <div class="d-flex justify-content-end mb-0 mt-2 mr-2">
						<b-button variant="primary"> Total Alive Birds : {{ this.total_chick ? total_chick : 0 }} </b-button>
					</div> -->
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Data Entry Date<span class="text-danger">*</span></template>
								<validation-provider #default="{ errors }" name="Entry Date" :rules="{ required: true }">
									<b-form-datepicker
										v-model="FormData.entry_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Feed/Supplement Name<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Select Feed/Supplement Name" rules="required">
										<v-select
											v-model="selectFeedList"
											:options="feedList"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
											@input="getBatchNumberList"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Batch No<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Select Batch No" rules="required">
										<v-select
											v-model="selectBatchList"
											:options="batchList"
											label="batch_no"
											clearable:false
											@input="getBatchDetails"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- <b-col md="6">
							<b-form-group>
								<template v-slot:label> Select Feed Types<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Select Feed Types"
										rules="required"
									>
										<v-select
											v-model="selectFeedType"
											:options="feedType"
											label="name"
											clearable:false
											:reduce="(e) => e.id"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col> -->
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Stock (kg)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="Stock Type" rules="required">
										<b-form-input
											placeholder="Stock Type"
											type="text"
											id="quantity"
											name="total_stock"
											v-model="batchDetails.quantity"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Unit Cost (Rs.)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name=" Unit Cost (Rs.)" rules="required">
										<b-form-input
											v-model="batchDetails.rate_per_kg"
											placeholder=" Unit Cost (Rs.)"
											type="text"
											name=" Unit Cost (Rs.)"
											disabled
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Feed Loaded (Kg)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Feed Loaded (Kg)"
										:rules="{
											required: true,
											max_value: this.batchDetails.quantity,
											regex: RegExp(/^\d+(\.\d{1,4})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.feed_loaded"
											placeholder="Feed Loaded (Kg)"
											type="text"
											name="feed_loaded"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Leftover Feed(Kg)</template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Leftover Feed(Kg)"
										:rules="{
											max_value: this.batchDetails.quantity,
											regex: RegExp(/^\d+(\.\d{1,4})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.leftover_feed"
											placeholder="Leftover Feed(Kg)"
											type="text"
											name="leftover_feed"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selected: null,
			selectFeedList: "",
			selectFeedType: "",
			selectBatchList: "",
			FormData: {
				quantity: null,
				rate_per_kg: null,
				feed_loaded: null,
				total_cost: null,
				leftover_feed: null,
			},
			batchDetailList: [],
			feedList: [],
			batchList: [],
			feedType: [],
			batch_no: null,
			batchDetails: [],
		};
	},
	created: function () {
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
		this.getFeedType();
		this.getFeeds();
	},
	methods: {
		goBack() {
			this.$router.push(`/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`);
		},
		getFeeds() {
			axiosIns
				.get(`web/get-all-feeds/${this.flockId}`)
				.then((response) => {
					this.feedList = response.data.feed_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getBatchNumberList(id) {
			const feed_id = this.$route.params.id;
			axiosIns
				.get(`web/farm/${this.farmId}/feed/${id}/batch-list`)
				.then((response) => {
					this.batchList = response.data.batch_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFeedType() {
			axiosIns
				.get(`web/feed-type`)
				.then((response) => {
					this.feedType = response.data.feed_type;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		addFeed(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("feed_id", this.selectFeedList);
					data.append("batch_no", this.batch_no);
					data.append("feed_type_id", this.selectFeedType);
					data.append("entry_date", this.FormData.entry_date);
					data.append("stock", this.batchDetails.quantity);
					data.append("unit_cost", this.batchDetails.rate_per_kg);
					data.append("feed_loaded", this.FormData.feed_loaded);
					data.append("leftover_feed", this.FormData.leftover_feed);
					// const farm_id = this.$route.params.farmId;
					axiosIns
						// .post(`web/hatchery/${hatchery_id}/purchaseVaccine`, data)
						.post(`web/farm/${this.farmId}/shed/${this.shedId}/flock/${this.flockId}/feed-entry`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								path: `/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
		getBatchDetails(batchDetails) {
			this.batch_no = batchDetails.batch_no;
			let batch_id = batchDetails.id;
			axiosIns
				.get(`web/farm/${this.farmId}/feed/batch/${batch_id}/batch-info`)
				.then((response) => {
					this.batchDetails = response.data.batch_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
